import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { HeaderComponent } from '@/app/_types/components/header-component';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import styles from './CtaBanner.module.scss';

const CtaBanner = (props: HeaderComponent) => {
  const { static_title, paragraph, dynamic_titles, cta_button } = props;

  return (
    <div className={styles.insuranceBannerWrapper}>
      <div className="container">
        <div className={styles.bannerContent}>
          <div className="headingElement full-width">
            {static_title && <h1 className="titleXXl light">{static_title}</h1>}
            <div className={styles.offeringText}>
              {dynamic_titles
                ? Array.isArray(dynamic_titles) &&
                  dynamic_titles.map((text, index) => {
                    return (
                      <div
                        className={
                          dynamic_titles.length > 1
                            ? styles.offeringTextItem
                            : ''
                        }
                        key={index}
                      >
                        <h2 className="titleXXl">{text?.option}</h2>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div className={styles.paragraph}>
            {paragraph && <div className="normal">{paragraph}</div>}
          </div>
          {cta_button?.text && (
            <ButtonCustom
              btnText={cta_button.text}
              href={
                cta_button?.pdf
                  ? getDirectusAssetURI(cta_button?.pdf ?? '') || ''
                  : cta_button.url
              }
              target={cta_button?.new_tab ? '_blank' : '_self'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
