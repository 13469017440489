'use client';

import React, { useEffect, useRef, useState } from 'react';

import { StepRepeater } from '@/app/_types/guideline-page';

import Styles from './Steps.module.scss';

type StepsProps = {
  title?: string | null;
  preText?: string | null;
  steps: StepRepeater[];
};

const Steps = (props: StepsProps) => {
  const { title, preText, steps } = props;

  const stepsContainerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  useEffect(() => {
    const checkScrollable = () => {
      const container = stepsContainerRef.current;

      if (container) {
        setIsScrollable(container.scrollWidth > container.clientWidth);
      }
    };

    checkScrollable();

    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [steps]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isScrollable) return;
    const container = stepsContainerRef.current;

    if (!container) return;
    isDown = true;
    container.classList.add(Styles.active);
    container.classList.add(Styles.disableTextSelection);
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
  };

  const handleMouseLeave = () => {
    if (!isScrollable) return;
    const container = stepsContainerRef.current;

    if (!container) return;
    isDown = false;
    container.classList.remove(Styles.active);
    container.classList.remove(Styles.disableTextSelection);
  };

  const handleMouseUp = () => {
    if (!isScrollable) return;
    const container = stepsContainerRef.current;

    if (!container) return;
    isDown = false;
    container.classList.remove(Styles.active);
    container.classList.remove(Styles.disableTextSelection);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown || !isScrollable) return;
    const container = stepsContainerRef.current;

    if (!container) return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - startX) * 2;

    container.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.stepsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
          </div>
          {steps && (
            <div
              className={Styles.stepsContainer}
              ref={stepsContainerRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              style={{ cursor: isScrollable ? 'grab' : 'default' }}
            >
              <div className={Styles.stepsCardsList}>
                {steps.map((step, index) => (
                  <div key={index} className={Styles.stepCard}>
                    <div className={Styles.stepCardCounter}>
                      <div className={Styles.stepCardNumber}>{index + 1}</div>
                    </div>
                    <div className={Styles.stepsCardBody}>
                      <div className="headingElement">
                        <h3 className="titleMd">{step.title}</h3>
                      </div>
                      <div className={Styles.stepCardDescription}>
                        {step.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Steps;
