'use client';

import cn from 'classnames';
import React, { useState } from 'react';

import { AboutUsVariation2 } from '@/app/_types/about-us';

import MobileDropdown from './MobileDropdown';
import Styles from './VerticalTab.module.scss';

type Props = {
  leadership_pre_text: string;
  leadership_title: string;
  leadership: AboutUsVariation2['leadership'];
};

const VerticalTab = (props: Props) => {
  const { leadership_pre_text, leadership_title, leadership } = props;
  const [activeTab, setActiveTab] = useState(
    leadership[0].leadership_tab_id.id,
  );

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className="pageSection">
      <div className="container">
        {leadership_pre_text && (
          <div className="headingElement">
            <h3 className="subTitle"> {leadership_pre_text} </h3>
            {leadership_title && (
              <h2 className="titleXl">{leadership_title}</h2>
            )}
          </div>
        )}

        <div className={Styles.dekstopNavigation}>
          <div className={Styles.verticalTabWrapper}>
            <ul className={Styles.tabs}>
              {leadership.map((company, index) => (
                <li
                  key={index}
                  className={
                    activeTab === company.leadership_tab_id.id
                      ? Styles.active
                      : ''
                  }
                  onClick={() => handleTabClick(company.leadership_tab_id.id)}
                >
                  <div className="headingElement">
                    <h3 className={cn(Styles.companyName, 'titleMd')}>
                      {company.leadership_tab_id.group_name}
                    </h3>
                  </div>
                </li>
              ))}
            </ul>

            <div className={Styles.tabContainer}>
              {leadership.map((company, index) => (
                <div
                  key={index}
                  id={company.leadership_tab_id.group_name}
                  className={`${Styles.tabContent} ${
                    activeTab === company.leadership_tab_id.id
                      ? Styles.activeContent
                      : ''
                  }`}
                >
                  {activeTab === company.leadership_tab_id.id &&
                    company.leadership_tab_id.members.map(
                      (employee, empIndex) => (
                        <div
                          key={empIndex}
                          className={cn(
                            'headingElement',
                            Styles.companyDetails,
                          )}
                        >
                          <h4 className="titleSm medium">
                            {employee.members_id.name}
                          </h4>
                          <p className="text-primary medium">
                            {employee.members_id.title}
                          </p>
                        </div>
                      ),
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={Styles.mobileNavigation}>
          <MobileDropdown {...props} />
        </div>
      </div>
    </div>
  );
};

export default VerticalTab;
