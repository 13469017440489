'use client';

import { Divider } from 'antd';
import Image from 'next/image';
import React, { useState } from 'react';

import PageContent from '@/app/_components/page-content/PageContent';
import { PartnerM2M } from '@/app/_types/components/partner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './PartnershipTabs.module.scss';
type PartnershipTabsTypes = {
  partners: PartnerM2M[];
  title?: string | null;
  preText?: string | null;
  filesMeta: FilesMeta;
};
const PartnershipTabs = (props: PartnershipTabsTypes) => {
  const { title, preText, partners, filesMeta } = props;

  const [activeTab, setActiveTab] = useState<number | null>(0);

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.partnershipWrapper}>
          <div className={Styles.partnershipContentContainer}>
            <div className={Styles.partnershipContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
          </div>
          <div className={Styles.partnershipTabsContainer}>
            <div className={Styles.partnershipTabsCardsList}>
              {partners &&
                partners?.map(({ partners_component_id }, index) => (
                  <div
                    key={index}
                    className={`${Styles.partnershipTabsCard} ${activeTab === index ? Styles.active : ''}`}
                    onClick={() => setActiveTab(index)}
                  >
                    <Image
                      src={
                        getDirectusAssetURI(
                          partners_component_id?.logo ?? '',
                        ) || ''
                      }
                      alt={getAltProperty(
                        filesMeta,
                        partners_component_id?.logo ?? '',
                        'product logo',
                      )}
                      width={234}
                      height={94}
                    />
                    <div className={Styles.partnershipTabsCardBody}>
                      <div className="headingElement">
                        <h3 className="titleSm">
                          {partners_component_id?.title}
                        </h3>
                      </div>
                      <div className={Styles.description}>
                        {partners_component_id?.paragraph}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <Divider className={Styles.quickLinkDivider} />
            {activeTab !== null &&
              partners?.[activeTab]?.partners_component_id &&
              partners?.[activeTab]?.partners_component_id?.details &&
              partners[activeTab].partners_component_id.details.map(
                ({ copy_component_id }, idx) => (
                  <div className={Styles.partnershipTabContent} key={idx}>
                    <PageContent {...copy_component_id} smallContent={true} />
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipTabs;
