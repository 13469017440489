'use client';
import cn from 'classnames';
import React from 'react';

import { CopyComponent } from '@/app/_types/components/copy-component';
import { PartnerComponent } from '@/app/_types/product-landing';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './PageContent.module.scss';

type PageContentTypes = (CopyComponent | PartnerComponent) & {
  smallContent?: boolean;
  btnVariant?: string;
};

const PageContent = (props: PageContentTypes) => {
  const {
    pre_text,
    title,
    paragraph,
    smallContent = false,
    cta_text,
    cta_url,
    btnVariant = 'btnPrimaryOutline',
  } = props;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.pageContentWrapper}>
          <div className={Styles.pageContentContainer}>
            <div className={Styles.pageContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div
                className={cn(
                  Styles.pageContentSection,
                  smallContent && Styles.smallContent,
                )}
              >
                <div
                  className={Styles.pageContentDetails}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
                {cta_text && (
                  <ButtonCustom
                    btnText={cta_text}
                    className={btnVariant}
                    href={cta_url ?? ''}
                    extraClass={Styles.pageContentBtn}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
