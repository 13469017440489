import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Styles from './History.module.scss';

type HistoryTypes = {
  content: Array<{
    history_tab_id: {
      id: string;
      paragraph: string;
      year: string;
    };
  }>;
};

const History = (props: HistoryTypes) => {
  const { content } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const listRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    if (listRef.current) {
      const items = Array.from(listRef.current.children) as HTMLDivElement[];
      const { top: listTop } = listRef.current.getBoundingClientRect();

      let newIndex = 0;

      items.forEach((item, index) => {
        const { top } = item.getBoundingClientRect();

        if (top - listTop < window.innerHeight / 2) {
          newIndex = index;
        }
      });

      setActiveIndex(newIndex);
    }
  };

  const handleClick = (index: number) => {
    setActiveIndex(index);
    if (listRef.current) {
      const targetItem = listRef.current.children[index] as HTMLDivElement;

      targetItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    const refCurrent = listRef.current;

    refCurrent?.addEventListener('scroll', handleScroll);
    return () => {
      refCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={Styles.historyWrapper}>
      <div className={Styles.historyList} ref={listRef}>
        {content.map((data, index) => (
          <div
            key={index}
            className={cn(
              Styles.historyItem,
              activeIndex === index && Styles.active,
            )}
            onClick={() => handleClick(index)}
          >
            <div className={Styles.historyTitle}>
              {data.history_tab_id.year && (
                <div className="headingElement text-primary">
                  <h4 className="titleMd">
                    {data.history_tab_id.year.toLocaleString()}
                  </h4>
                </div>
              )}
            </div>
            {data.history_tab_id.paragraph && (
              <div className={Styles.historyDescription}>
                <div className={Styles.yellowDot}></div>
                <span className="normal">{data.history_tab_id.paragraph}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
