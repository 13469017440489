'use client';

import { Form, message } from 'antd';
import { usePathname } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import FormInput from '@/app/_components/form/FormInput';
import { Form as FormType } from '@/app/_types/components/form';
import { saveFormData } from '@/app/actions';

import Styles from './ContactForm.module.scss';

type Props = FormType;

const ContactForm = (props: Props) => {
  const pathname = usePathname();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const saveData = saveFormData.bind(null, pathname, props.submission_key);

  const initialValues = useMemo(() => {
    const values = props.form_inputs?.reduce((acc, curr) => {
      const itemName = curr.item.name?.toString();

      if (!itemName) {
        return { ...acc };
      }
      return { ...acc, [itemName]: '' };
    }, {});

    form.setFieldsValue(values);
    return values;
  }, [form]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const handleSubmit = async (data: any) => {
    const response = await saveData(data);

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
    } else {
      form.resetFields();
      messageApi.open({
        type: 'success',
        content: props.submitted_paragraph,
        duration: 10,
      });
    }
  };

  return (
    <div className="pageSection">
      <div className="container">
        {contextHolder}

        <div className={Styles.contactFormWrapper}>
          <div className="headingElement">
            <h3 className="subTitle">{props.pre_text}</h3>
            <h2
              className="titleXl"
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></h2>
          </div>
          <Form
            className={Styles.contactForm}
            size="large"
            form={form}
            onFinish={handleSubmit}
            initialValues={initialValues}
          >
            <div className={Styles.contactFormField}>
              {props.form_inputs.map((input) => (
                <FormInput key={input.id} input={input} form={form} />
              ))}
            </div>
            <div>
              <ButtonCustom
                htmlType="submit"
                disabled={!form.isFieldsTouched() || !isFormValid}
              >
                {props.submit_text}
              </ButtonCustom>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
