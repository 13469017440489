'use client';
import React from 'react';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import FAQs from '@/app/_components/faqs/FAQs';
import HelpCard from '@/app/_components/help-card/HelpCard';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { FilesMeta } from '@/app/_types/file';
import { ProductCategoryPageVariation1 as ProductCategoryVariation1Type } from '@/app/_types/product-category-page';

import ResourceSection from '../ResourceSection';

import CtaBanner from './CtaBanner';
import ProductListing from './ProductListing';

type ProductCategoryVariation1Props = ProductCategoryVariation1Type & {
  breadcrumbs?: Breadcrumb[] | null;
  filesMeta?: FilesMeta;
};

const ProductCategoryVariation1 = (props: ProductCategoryVariation1Props) => {
  const {
    header,
    category,
    call_to_action,
    faq_pre_text,
    faq_title,
    faq_sections,
    breadcrumbs,
    media_and_resources_title,
    media_and_resources_pre_text,
    media_and_resources_paragraph,
    media_and_resources,
    filesMeta = {},
  } = props;

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      {header && (
        <CtaBanner
          banner={header}
          subCategories={category.sub_categories}
          filesMeta={filesMeta}
        />
      )}
      {/* If the category has sub categories */}
      {category.sub_categories &&
        category.sub_categories?.length > 0 &&
        category.sub_categories.map((sub_category) => {
          return (
            <ProductListing
              key={sub_category.id}
              heading={sub_category.title}
              subHeading={sub_category.pre_text}
              products={sub_category.products || undefined}
              filesMeta={filesMeta}
            />
          );
        })}
      {call_to_action && <HelpCard {...call_to_action} filesMeta={filesMeta} />}
      {faq_sections?.length && (
        <FAQs
          faqsList={faq_sections}
          preText={faq_pre_text}
          faqTitle={faq_title}
        />
      )}
      {media_and_resources && media_and_resources?.length > 0 && (
        <ResourceSection
          title={media_and_resources_title}
          preText={media_and_resources_pre_text}
          paragraph={media_and_resources_paragraph}
          resources={media_and_resources}
          filesMeta={filesMeta}
        />
      )}
    </>
  );
};

export default ProductCategoryVariation1;
