'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import arrowNext from '@/app/_icons/arrow-next.png';
import arrowPrev from '@/app/_icons/arrow-prev.png';
import { CampaignSliderType } from '@/app/_types/components/campaign-slider';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './CampaignSlider.module.scss';

import 'swiper/css';

SwiperCore.use([Navigation]);

type CampaignSliderProps = {
  slides: CampaignSliderType[];
  filesMeta: FilesMeta;
  fullWidth?: boolean;
};

const CampaignSlider = (props: CampaignSliderProps) => {
  const { slides, filesMeta, fullWidth = false } = props;
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleSwipeNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const handleSwipePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <div className="pageSection">
      <div className={Styles.campaignSliderWrapper}>
        <div
          className={cn(
            fullWidth ? Styles.fullWidthContainer : Styles.smallContainer,
          )}
        >
          <div className={Styles.helpCardContainer}>
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              spaceBetween={40}
              slidesPerView={1}
              navigation
              loop={true}
              className="mySwiper"
            >
              {slides &&
                slides.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={Styles.helpCardContent}>
                      <div className={Styles.leftContent}>
                        {item?.cta_banner_id?.title && (
                          <div className={cn('headingElement', Styles.heading)}>
                            <h2 className="titleXl strong">
                              {item.cta_banner_id.pre_text && (
                                <span className="normal">
                                  {item.cta_banner_id.pre_text}
                                </span>
                              )}

                              {item.cta_banner_id?.title &&
                                item.cta_banner_id?.title}
                            </h2>
                          </div>
                        )}

                        <div className={Styles.description}>
                          {item.cta_banner_id.paragraph &&
                            item.cta_banner_id.paragraph}
                        </div>

                        {item.cta_banner_id.call_to_action && (
                          <ButtonCustom
                            btnText={item.cta_banner_id.call_to_action?.text}
                            className="btnSecondary"
                            extraClass={Styles.ctaCardBtn}
                            href={item.cta_banner_id.call_to_action?.url}
                          />
                        )}
                      </div>
                      <div className={Styles.rightContent}>
                        {item.cta_banner_id.image && (
                          <Image
                            src={
                              getDirectusAssetURI(item.cta_banner_id.image) ??
                              ''
                            }
                            alt={getAltProperty(
                              filesMeta,
                              item.cta_banner_id.image ?? '',
                              'Banner',
                            )}
                            fill
                            className={Styles.cardImage}
                          />
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className={Styles.swipeWrapper}>
            <button
              className={Styles.swiperIconBtn}
              onClick={handleSwipePrevClick}
            >
              <Image src={arrowPrev} alt="Previous" height={30} width={30} />
              Previous
            </button>
            <button
              className={Styles.swiperIconBtn}
              onClick={handleSwipeNextClick}
            >
              Next
              <Image src={arrowNext} alt="Next" height={30} width={30} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSlider;
