'use client';
import React from 'react';

import ProductCard from '@/app/_components/product-card/ProductCard';
import { FilesMeta } from '@/app/_types/file';

import Styles from './OurProduct.module.scss';

type OurProductsProps = {
  preText?: string | null;
  title?: string | null;
  description?: string | null;
  products: Array<{
    id?: string | number;
    icon?: string | null;
    title?: string | null;
    paragraph?: string | null;
    url?: string | null;
    description?: string | null;
  }>;
  filesMeta?: FilesMeta;
};

const OurProducts = (props: OurProductsProps) => {
  const { preText, title, description, products, filesMeta } = props;

  return (
    <div className="pageSection">
      <div className={Styles.productWrapper}>
        <div className="container">
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {description && (
              <div className={Styles.sectionRightContent}>
                <div className={Styles.sectionDescription}>{description}</div>
              </div>
            )}
          </div>
        </div>
        <div className="container">
          {products && (
            <div className={Styles.productCards}>
              {products.map((product, index) => (
                <ProductCard key={index} {...product} filesMeta={filesMeta} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
