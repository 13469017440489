'use client';

import { Divider } from 'antd';
import cn from 'classnames';
import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import ButtonCustom from '@/app/_components/button/ButtonCustom';

import styles from './PersonalizedOffering.module.scss';

type PropsType = {
  preText?: string | null;
  title?: string | null;
  paragraph?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
};

const PersonalizedOffering = (props: PropsType) => {
  const { preText, title, paragraph, ctaText, ctaUrl } = props;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className="pageSection" ref={ref}>
      <div className={styles.personalizedOfferingWrapper}>
        <div className="container">
          <div className={styles.personalizedOffering}>
            <div className={cn('headingElement', styles.countWrapper)}>
              <div className={styles.countItem}>
                <h2 className="titleXXXl">
                  {inView && <CountUp end={100} duration={3} />}
                </h2>
                <p className="light">new clients weekly</p>
              </div>
              <div className={styles.countItem}>
                <h2 className="titleXXXl">
                  {inView && <CountUp end={97} duration={3} suffix="%" />}
                </h2>
                <p className="light">Payout rate</p>
              </div>
              <div className={styles.countItem}>
                <h2 className="titleXXXl">
                  {inView && (
                    <CountUp end={1000} duration={3} prefix="+" separator="" />
                  )}
                </h2>
                <p className="light">Happy clients</p>
              </div>
            </div>
            <Divider className={styles.quickLinkDivider} />
            <div className={cn('headingElement', styles.contentWrapper)}>
              <div className={styles.heading}>
                {preText && <p className="medium">{preText}</p>}
                {title && <h3 className="titleLg">{title}</h3>}
              </div>
              <div className={styles.paragraph}>
                {paragraph && (
                  <div
                    className="normal"
                    dangerouslySetInnerHTML={{
                      __html: paragraph,
                    }}
                  ></div>
                )}
              </div>
              {ctaText && (
                <ButtonCustom
                  btnText={ctaText}
                  href={ctaUrl ?? ''}
                  className="btnPrimaryAlt"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalizedOffering;
