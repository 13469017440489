export const splitSentence = (sentence: string, splitPercentage: number) => {
  const words = sentence.split(' ');
  const totalCount = words.length;
  const splitIndex = totalCount * (splitPercentage / 100.0);

  return [
    words.slice(0, splitIndex).join(' '),
    words.slice(splitIndex).join(' '),
  ];
};

/**
 * add leading and trailing slashes to a path
 * 
 * @param path 
 */
export const sanitizeAbsolutePath = (path: string) => {
  if (!path.endsWith('/')) {
    path = path + '/';
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return path;
};

/**
 * Parse the "POINT (x y)" string value into lat long
 * 
 * @param value
 */
export const parseCoordinate = (value: string): {lat: number, lng: number} | null => {
  const regex = new RegExp('POINT \\((?<lat>.*) (?<lng>.*)\\)', 'gm');

  const match = regex.exec(value);

  if (match && match.groups) {
    return {lat: parseFloat(match.groups['lat']), lng: parseFloat(match.groups['lng'])}
  }
  return null
};
