import cn from 'classnames';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { CopyComponent } from '@/app/_types/components/copy-component';

import Styles from './UmaPageContent.module.scss';

type PageContentTypes = CopyComponent & {
  smallContent?: boolean;
};

const PageContent = (props: PageContentTypes) => {
  const {
    pre_text,
    title,
    paragraph,
    smallContent = false,
    cta_text,
    cta_url,
    cta2_text,
    cta2_url,
  } = props;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.pageContentWrapper}>
          <div className={Styles.pageContentContainer}>
            <div className={Styles.pageContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div
                className={cn(
                  Styles.pageContentSection,
                  smallContent && Styles.smallContent,
                )}
              >
                <div
                  className={Styles.pageContentDetails}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
                <div className={Styles.buttonWrapper}>
                  {cta_text && (
                    <ButtonCustom
                      btnText={cta_text}
                      className="btn"
                      href={cta_url ?? ''}
                      extraClass={Styles.pageContentBtn}
                    />
                  )}
                  {cta2_text && (
                    <ButtonCustom
                      btnText={cta2_text}
                      className="btnPrimaryOutline"
                      href={cta2_url ?? ''}
                      extraClass={Styles.pageContentBtn}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
