'use client';
import Image from 'next/image';
import React from 'react';

import { CTABanner } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './HelpCard.module.scss';

type HelpCardProps = CTABanner & {
  filesMeta?: FilesMeta;
  contactNumber?: string | null;
  contactDescription?: string | null;
};

const HelpCard = (props: HelpCardProps) => {
  const {
    pre_text,
    title,
    paragraph,
    call_to_action,
    image,
    filesMeta = {},
    contactNumber,
    contactDescription,
  } = props;

  return (
    <div className={Styles.helpCardContainer}>
      <div className="container">
        <div className={Styles.helpCardContent}>
          <div className={Styles.leftContent}>
            <div className="headingElement">
              {pre_text && <h4 className="subTitle">{pre_text}</h4>}
              {title && <h2 className="titleXl">{title}</h2>}
            </div>

            <div className={Styles.description}>{paragraph}</div>

            {contactNumber && (
              <div className={Styles.contactContainer}>
                <div className="headingElement">
                  {title && <h4 className="titleMd">{contactNumber}</h4>}
                </div>

                {contactDescription && (
                  <div className={Styles.contactDescription}>
                    {contactDescription}
                  </div>
                )}
              </div>
            )}

            <ButtonCustom
              btnText={call_to_action?.text}
              className="btnPrimaryAlt"
              extraClass={Styles.ctaCardBtn}
              href={call_to_action?.url}
            />
          </div>
          <div className={Styles.rightContent}>
            {image && (
              <Image
                src={getDirectusAssetURI(image) ?? ''}
                alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                height={270}
                width={414}
                className={Styles.cardImage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
