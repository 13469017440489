'use client';

import { Form, message } from 'antd';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import FormInput from '@/app/_components/form/FormInput';
import { Form as FormType } from '@/app/_types/components/form';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { saveFormData } from '@/app/actions';

import Styles from './ContactForm.module.scss';

type ContactFormProps = FormType & {
  filesMeta: FilesMeta;
};

const ContactForm = (props: ContactFormProps) => {
  const {
    title,
    pre_text,
    submit_text,
    submission_key,
    form_inputs,
    description,
    image,
    filesMeta,
  } = props;

  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const values = Form.useWatch([], form);
  const [messageApi, contextHolder] = message.useMessage();
  const pathname = usePathname();
  const saveData = saveFormData.bind(null, pathname, submission_key);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const handleSubmit = async (data: any): Promise<boolean> => {
    const response = await saveData(data);

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      return false;
    } else {
      form.resetFields();
      messageApi.open({
        type: 'success',
        content: props.submitted_paragraph,
        duration: 10,
      });
      return true;
    }
  };

  return (
    <div className="pageSection">
      <div className={Styles.contactFormWrapper}>
        <div className="container">
          {contextHolder}
          <div className={Styles.contactFormContainer}>
            <div className={Styles.formSection}>
              <div className="headingElement">
                <h3 className="subTitle">{pre_text}</h3>
                <h2 className="titleXl">{title}</h2>
                <p>{description}</p>
              </div>
              <Form
                className={Styles.contactForm}
                size="large"
                form={form}
                onFinish={handleSubmit}
              >
                <div className={Styles.contactFormField}>
                  {form_inputs.map((input) => (
                    <FormInput key={input.id} input={input} form={form}/>
                  ))}
                </div>
                <div>
                  <ButtonCustom
                    htmlType="submit"
                    disabled={!form.isFieldsTouched() || !isFormValid}
                  >
                    {submit_text}
                  </ButtonCustom>
                </div>
              </Form>
            </div>
            <div className={Styles.contactFormImageContainer}>
              <Image
                src={getDirectusAssetURI(image ?? '') || ''}
                alt={getAltProperty(filesMeta, image ?? '', 'Contact image')}
                fill
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
