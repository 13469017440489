'use client';
import Image from 'next/image';
import Link from 'next/link';
import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import arrowIcon from '@/app/_images/arrow-icon.png';
import { OfferingCardM2M } from '@/app/_types/components/offering-card';
import 'swiper/css';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './MediaSection.module.scss';

SwiperCore.use([Navigation, Mousewheel]);

type MediaSectionTypes = {
  title?: string | null;
  preText?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
  mediaList: OfferingCardM2M[];
  filesMeta: FilesMeta;
};

const MediaSection = (props: MediaSectionTypes) => {
  const { title, preText, ctaText, ctaUrl, mediaList, filesMeta } = props;
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleSwipeClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="pageSection">
      <div className={Styles.resourceSectionWrapper}>
        <div className="container">
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
          </div>
        </div>

        {mediaList && (
          <div className={Styles.resourceContainer}>
            <div className={Styles.resourceList}>
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                spaceBetween={40}
                slidesPerView={1}
                freeMode={true}
                mousewheel={true}
                navigation
                breakpoints={{
                  640: {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                  },
                }}
                className="mySwiper"
              >
                {mediaList.map(({ offering_cards_id }, index) => (
                  <SwiperSlide key={index}>
                    <Link
                      href={offering_cards_id?.cta_url ?? ''}
                      className={Styles.resourceCard}
                    >
                      <Image
                        src={
                          getDirectusAssetURI(offering_cards_id?.icon ?? '') ??
                          ''
                        }
                        alt={getAltProperty(
                          filesMeta,
                          offering_cards_id?.icon ?? '',
                          'Media icon',
                        )}
                        width={120}
                        height={120}
                      />
                      <div className={Styles.resourceCardBody}>
                        <div className="headingElement text-center">
                          <h3 className="titleMd">
                            {offering_cards_id?.title}
                          </h3>
                        </div>
                        <div className={Styles.description}>
                          {offering_cards_id?.paragraph}
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="container">
                <div className={Styles.swipeWrapper}>
                  <button
                    className={Styles.swiperIconBtn}
                    onClick={handleSwipeClick}
                  >
                    Swipe
                    <Image
                      src={arrowIcon}
                      alt="arrow-icon"
                      height={18}
                      width={18}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={Styles.resourceFooter}>
              {ctaText && ctaUrl && (
                <ButtonCustom href={ctaUrl} btnText={ctaText} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaSection;
