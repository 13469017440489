'use client';

import Image from 'next/image';
import React from 'react';

import { FormNeededM2M } from '@/app/_types/components/forms-needed';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './IconCardSection.module.scss';

type IconCardProps = {
  offerings: FormNeededM2M[];
  filesMeta: FilesMeta;
};
const IconCardSection = (props: IconCardProps) => {
  const { offerings, filesMeta = {} } = props;

  return (
    <div>
      {offerings &&
        offerings.map(({ forms_needed_id }, index) => {
          return (
            <div key={index} className={Styles.iconCardWrapper}>
              <div className={Styles.iconCardLists}>
                <div className={Styles.desktopGrid}>
                  <div className={Styles.iconCard}>
                    <Image
                      src={
                        getDirectusAssetURI(forms_needed_id?.icon ?? '') || ''
                      }
                      alt={getAltProperty(
                        filesMeta,
                        forms_needed_id?.icon ?? '',
                        'Claim icon',
                      )}
                      width={60}
                      height={60}
                    />
                    <div className={Styles.iconCardBody}>
                      <h4 className={Styles.title}>{forms_needed_id?.title}</h4>
                      <div className={Styles.description}>
                        {forms_needed_id?.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default IconCardSection;
