'use client';

import React from 'react';

import ProductCard from '@/app/_components/product-card/ProductCard';
import { ProductM2M } from '@/app/_types/components/product-category';
import { FilesMeta } from '@/app/_types/file';

import Styles from './ProductListing.module.scss';

type ProductListingProps = {
  heading?: string | null;
  subHeading?: string | null;
  products?: ProductM2M[];
  filesMeta?: FilesMeta;
};

const ProductListing = (props: ProductListingProps) => {
  const { heading, subHeading, products, filesMeta = {} } = props;

  return (
    <div>
      <div className="pageSection">
        <div className={Styles.productWrapper}>
          <div className="container">
            <div className={Styles.sectionHeadingContainer}>
              <div className={Styles.sectionLeftContent}>
                {heading && (
                  <div className="headingElement">
                    {subHeading && (
                      <h3 className="subTitle">{subHeading}</h3>
                    )}
                    {heading && (
                      <h2 className="titleXl">{heading}</h2>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            {products && (
              <div className={Styles.productCards}>
                {products.map((productM2M, productIndex) => {
                  const product = productM2M.products_id;

                  return (
                  <ProductCard
                    key={productIndex}
                    {...product}
                    url={product.learn_more_cta_url}
                    filesMeta={filesMeta}
                  />
                )})}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
