import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { Blog } from '@/app/_types/blog';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { formatDate } from '@/app/_utils/date-format';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './BlogArticleCards.module.scss';

type BlogArticleCardsTypes = {
  blogList: Blog[];
  title?: string | null;
  preText?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
  filesMeta: FilesMeta;
};

const BlogArticleCards = (props: BlogArticleCardsTypes) => {
  const { preText, title, ctaText, ctaUrl, blogList, filesMeta } = props;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.blogArticleCardsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
          </div>
          {blogList && (
            <div className={Styles.blogsContainer}>
              <div className={Styles.blogsCardsList}>
                {blogList.map((blog, index) => {
                  return (
                    <Link href={''} key={index} className={Styles.blogCard}>
                      <div className={Styles.blogCardImageContainer}>
                        <Image
                          src={
                            getDirectusAssetURI(blog?.preview_image ?? '') ?? ''
                          }
                          alt={getAltProperty(
                            filesMeta,
                            blog?.preview_image ?? '',
                            'blog',
                          )}
                          className={Styles.blogCardImage}
                          width={218}
                          height={194}
                        />
                      </div>
                      <div className={Styles.blogCardContent}>
                        <div className={Styles.blogCardHeader}>
                          <h3 className={Styles.blogCardTitle}>{blog.title}</h3>
                          <div className={Styles.blogCardMeta}>
                            <p className={Styles.blogCardDate}>
                              {formatDate(blog.date)}
                            </p>
                            <p className={Styles.blogCardAuthor}>
                              {blog?.author}
                            </p>
                          </div>
                        </div>
                        <div
                          className={Styles.blogCardDescription}
                          dangerouslySetInnerHTML={{
                            __html: blog?.content ?? '',
                          }}
                        ></div>
                      </div>
                    </Link>
                  );
                })}
              </div>
              {ctaText && ctaUrl && (
                <ButtonCustom href={ctaUrl} btnText={ctaText} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogArticleCards;
