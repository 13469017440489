'use client';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import 'swiper/css';
import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { FilesMeta } from '@/app/_types/file';
import { HomeVariation1 } from '@/app/_types/home';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import cn from 'classnames';

import Styles from './CtaBanner.module.scss';

type CtaBannerProps = HomeVariation1['header_component'] & {
  filesMeta: FilesMeta;
};

const CtaBanner = (props: CtaBannerProps) => {
  const {
    static_title,
    paragraph,
    cta_button,
    image,
    dynamic_titles,
    filesMeta,
  } = props || {};

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 175) {
        setScrollPosition(scroll - 175);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 2000})`,
  };

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContainer}>
          <div className={Styles.ctaBannerContent}>
            <div className={Styles.ctaBannerHeadingContainer}>
              <div className="headingElement">
                {static_title && <h1 className="titleXXXl">{static_title}</h1>}
              </div>

              <div className={cn('headingElement', Styles.offeringText)}>
                {dynamic_titles
                  ? Array.isArray(dynamic_titles) &&
                    dynamic_titles.map((text, index) => {
                      return (
                        <div className={Styles.offeringTextItem} key={index}>
                          <h2 className="titleXXl">{text?.option}</h2>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            {paragraph && <div className={Styles.description}>{paragraph}</div>}

            {cta_button && (
              <ButtonCustom href={cta_button?.url} btnText={cta_button?.text} />
            )}
          </div>
          {image && (
            <div className={Styles.bannerImageContainer}>
              <div className={Styles.bannerImageWrapper} style={zoomStyle}>
                <Image
                  src={getDirectusAssetURI(image) ?? ''}
                  alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                  fill
                  className={Styles.bannerImage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
