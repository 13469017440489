'use client';
import { AutoComplete, Collapse, Input } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import slugify from 'react-slugify';

import CheckBoxCustom from '@/app/_components/checkbox/CheckBoxCustom';
import ChevronDown from '@/app/_icons/chevron-down.svg';
import ChevronLeft from '@/app/_icons/chevron-left.svg';
import FilterIcon from '@/app/_icons/filter.svg';
import {
  DocumentComponentM2M,
  DocumentFile,
  DocumentGroupM2M,
} from '@/app/_types/components/document-group';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './DocumentsListing.module.scss';
import NoResultsFound from './NoResultsFound';

type DocumentsListingProps = {
  documentsData: DocumentGroupM2M[];
  documentName?: string;
  suggestions?: string[] | null;
};

const DocumentsListing = (props: DocumentsListingProps) => {
  const { documentsData, documentName, suggestions } = props;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(documentsData);
  const [suggestionOptions, setSuggestionOptions] = useState<
    { value: string }[]
  >([]);

  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterBtnRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    filterAndSearchData();
  }, [selectedFilters, searchTerm, documentsData]);

  const includesSearchTerm = (text: string, searchTerm: string): boolean =>
    text.toLowerCase().includes(searchTerm.toLowerCase());

  const filterFiles = (files: DocumentFile[], searchTerm: string) =>
    files.filter((file) =>
      includesSearchTerm(file?.directus_files_id?.title ?? '', searchTerm),
    );

  const filterAndMapDocuments = (
    documents: DocumentComponentM2M[],
    searchTerm: string,
  ) =>
    documents
      ?.map((doc) => ({
        ...doc,
        documents_component_id: {
          ...doc.documents_component_id,
          files: filterFiles(doc?.documents_component_id?.files, searchTerm),
        },
      }))
      .filter(
        (doc) =>
          includesSearchTerm(doc?.documents_component_id?.title, searchTerm) ||
          doc.documents_component_id.files.length > 0,
      );

  const searchDocuments = (
    categories: DocumentGroupM2M[],
    searchTerm: string,
  ) =>
    categories
      ?.map((category) => {
        const documentGroupId = category?.document_group_id;

        return {
          ...category,
          document_group_id: {
            ...documentGroupId,
            documents: filterAndMapDocuments(
              documentGroupId?.documents ?? [],
              searchTerm,
            ),
          },
        };
      })
      .filter(
        (category) =>
          includesSearchTerm(
            category.document_group_id?.title ?? '',
            searchTerm,
          ) || (category.document_group_id?.documents?.length ?? 0) > 0,
      );

  const filterAndSearchData = () => {
    let result = documentsData;

    if (selectedFilters.length > 0) {
      result = result.filter((item) =>
        selectedFilters.includes(
          item.document_group_id?.title?.toLowerCase() ?? '',
        ),
      );
    }

    if (searchTerm) {
      result = searchDocuments(result, searchTerm);
    }

    setFilteredData(result);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    if (suggestions) {
      const filteredSuggestions = suggestions
        .filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase()),
        )
        .map((suggestion) => ({ value: suggestion }));

      setSuggestionOptions(filteredSuggestions);
    }
  };

  const toggleFilter = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsFilterOpen((prev) => !prev);
  };

  const onFilterItemChange = (itemName: string, checked: boolean) => {
    setSelectedFilters((prev) =>
      checked
        ? [...prev, itemName.toLowerCase()]
        : prev.filter((item) => item.toLowerCase() !== itemName.toLowerCase()),
    );
  };

  const handleSelect = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterMenuRef.current &&
        !filterMenuRef.current.contains(event.target as Node) &&
        filterBtnRef.current &&
        !filterBtnRef.current.contains(event.target as Node)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const collapseItemsLevel1 = filteredData.map(({ document_group_id }) => ({
    key: slugify(document_group_id?.title),
    label: document_group_id.title,
    children: (
      <Collapse
        bordered={false}
        ghost={true}
        expandIconPosition="end"
        className={Styles.collapseLevel2}
        expandIcon={({ isActive }) => (
          <Image
            src={ChevronLeft}
            alt="Chevron Left"
            className={isActive ? Styles.openBox : Styles.closeBox}
            height={14}
            width={14}
          />
        )}
        items={document_group_id?.documents?.map(
          ({ documents_component_id }, index) => ({
            key: `${documents_component_id.id}-${index}`,
            label: documents_component_id.title,
            children: documents_component_id?.files?.map(
              ({ directus_files_id }, docIndex) => (
                <div key={docIndex} className={Styles.documentItemBox}>
                  <p className={Styles.documentItemTitle}>
                    {directus_files_id?.title}
                  </p>
                  <div className={Styles.documentItemDownloadSection}>
                    <a
                      href={
                        getDirectusAssetURI(
                          directus_files_id?.id ?? '',
                          true,
                        ) || ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Styles.documentItemDownloadLink}
                      download={directus_files_id?.title}
                    >
                      Download
                    </a>
                    <a
                      href={
                        getDirectusAssetURI(directus_files_id?.id ?? '') || ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Styles.documentItemDownloadLink}
                    >
                      View
                    </a>
                  </div>
                </div>
              ),
            ),
          }),
        )}
      />
    ),
  }));

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.documentsListingWrapper}>
          <div className={Styles.searchWrapper}>
            <div className={Styles.searchContainer}>
              <AutoComplete
                className={Styles.searchBox}
                options={suggestionOptions}
                onSearch={handleSearch}
                onSelect={handleSelect}
              >
                <Input.Search placeholder="Search..." />
              </AutoComplete>

              <a
                onClick={toggleFilter}
                className={Styles.filterBtn}
                ref={filterBtnRef}
              >
                <span className={Styles.btnText}>
                  Filter {`(${selectedFilters?.length})`}
                </span>
                <Image
                  src={FilterIcon}
                  alt="Filter"
                  className={Styles.btnIcon}
                  height={14}
                  width={14}
                />
              </a>
            </div>
            <div
              ref={filterMenuRef}
              className={cn(Styles.filterMenu, isFilterOpen && Styles.open)}
            >
              {documentsData?.map(({ document_group_id }, index) => (
                <div key={index} className={Styles.filterMenuItem}>
                  <div className="customInput mb-0">
                    <CheckBoxCustom
                      name={document_group_id?.title ?? ''}
                      label={document_group_id?.title ?? ''}
                      onChange={(e) =>
                        onFilterItemChange(
                          document_group_id?.title ?? '',
                          e.target.checked,
                        )
                      }
                      checked={selectedFilters?.includes(
                        document_group_id?.title?.toLowerCase() ?? '',
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {filteredData && filteredData.length > 0 ? (
            <div className={Styles.documentListings}>
              <Collapse
                accordion
                expandIconPosition={'end'}
                bordered={false}
                ghost={true}
                defaultActiveKey={[slugify(documentName) ?? '']}
                className={Styles.collapseLevel1}
                expandIcon={({ isActive }) => (
                  <Image
                    src={ChevronDown}
                    alt="Chevron Down"
                    className={isActive ? Styles.openBox : Styles.closeBox}
                    height={16}
                    width={16}
                  />
                )}
                items={collapseItemsLevel1}
              />
            </div>
          ) : (
            <NoResultsFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsListing;
