'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { HeaderComponent } from '@/app/_types/components/header-component';
import { FilesMeta } from '@/app/_types/file';
import { getDirectusAssetURI } from '@/app/_utils/assets';
import { splitSentence } from '@/app/_utils/text';

import Styles from './CtaBanner.module.scss';

type Props = HeaderComponent & {
  filesMeta?: FilesMeta;
}


const CtaBanner = (props: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 350) {
        setScrollPosition(scroll - 350);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 2000})`,
  };

  const splitHeading = splitSentence(props.static_title, 50);

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            <h1
              className={cn(
                'titleXXl text-primary strong',
                Styles.ctaBannerTitle,
              )}
            >
              <span className="light">{splitHeading[0]}</span>
              {splitHeading[1]}
            </h1>
          </div>
          <div className={Styles.description}>{props.paragraph}</div>
        </div>
      </div>
      { props.image && (
        <div className={Styles.bannerImageContainer}>
          <div className={Styles.bannerImageWrapper} style={zoomStyle}>
            <Image
              src={getDirectusAssetURI(props.image) || ''}
              alt="banner image"
              fill
              className={Styles.bannerImage}
              priority
            />
          </div>
        </div>
      ) }
    </div>
  );
};

export default CtaBanner;
