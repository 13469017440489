'use client';
import Image from 'next/image';
import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import arrowIcon from '@/app/_images/arrow-icon.png';
import { CopyComponentM2M } from '@/app/_types/components/copy-component';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './ResourceSection.module.scss';

import 'swiper/css';

SwiperCore.use([Navigation, Mousewheel]);

type ResourceSectionProps = {
  title?: string | null;
  preText?: string | null;
  paragraph?: string | null;
  resources?: CopyComponentM2M[] | null;
  filesMeta: FilesMeta;
};

const ResourceSection = (props: ResourceSectionProps) => {
  const { title, preText, paragraph, resources, filesMeta } = props;
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleSwipeClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="pageSection">
      <div className={Styles.resourceSectionWrapper}>
        <div className="container">
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div className={Styles.sectionRightContent}>
                <div className={Styles.sectionDescription}>{paragraph}</div>
              </div>
            )}
          </div>
        </div>

        {resources && resources.length > 0 && (
          <div className={Styles.resourceContainer}>
            <div className={Styles.resourceList}>
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                spaceBetween={40}
                slidesPerView={1}
                freeMode={true}
                mousewheel={true}
                navigation
                breakpoints={{
                  640: {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                  },
                }}
                className="mySwiper"
              >
                {resources.map(({ copy_component_id }, index) => (
                  <SwiperSlide key={index}>
                    <div className={Styles.resourceCard}>
                      <Image
                        src={
                          getDirectusAssetURI(copy_component_id?.image ?? '') ||
                          ''
                        }
                        alt={getAltProperty(
                          filesMeta,
                          copy_component_id?.image ?? '',
                          'Icon',
                        )}
                        width={120}
                        height={120}
                      />
                      <div className={Styles.resourceCardBody}>
                        <div className="headingElement text-center">
                          <h3 className="titleMd">
                            {copy_component_id?.title}
                          </h3>
                        </div>
                        <div
                          className={Styles.description}
                          dangerouslySetInnerHTML={{
                            __html: copy_component_id?.paragraph,
                          }}
                        ></div>
                      </div>
                      {copy_component_id?.cta_text &&
                        copy_component_id?.cta_url && (
                          <ButtonCustom
                            btnText={copy_component_id?.cta_text}
                            href={copy_component_id?.cta_url}
                          />
                        )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {resources.length > 3 && (
                <div className="container">
                  <div className={Styles.swipeWrapper}>
                    <button
                      className={Styles.swiperIconBtn}
                      onClick={handleSwipeClick}
                    >
                      Swipe
                      <Image
                        src={arrowIcon}
                        alt="arrow-icon"
                        height={18}
                        width={18}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourceSection;
