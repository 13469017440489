'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ProductM2M } from '@/app/_types/components/product-category';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './IconCardSection.module.scss';

type IconCardProps = {
  heading: string;
  subHeading: string;
  products: ProductM2M[];
  filesMeta: FilesMeta;
};

const IconCardSection = (props: IconCardProps) => {
  const { products, heading, subHeading, filesMeta = {} } = props;

  return (
    <div>
      <div className="pageSection">
        <div className="container">
          <div className={Styles.iconCardWrapper}>
            <div className={Styles.iconCardContentContainer}>
              <div className={Styles.iconCardContentHeadingSection}>
                {heading && (
                  <div className="headingElement">
                    {subHeading && (
                      <h3 className="subTitle">{subHeading}</h3>
                    )}
                    <h2 className="titleXl">{heading}</h2>
                  </div>
                )}
              </div>
              {/* {details && (
                <div
                  className={Styles.iconCardContentDetails}
                  dangerouslySetInnerHTML={{ __html: details }}
                />
              )} */}
            </div>
            {products && (
              <div className={Styles.iconCardLists}>
                <div className={Styles.desktopGrid}>
                  {products.map((productM2M) => {
                    const product = productM2M.products_id;

                    return (
                      <Link key={productM2M.id} href={product.learn_more_cta_url ?? ''}>
                        <div className={Styles.iconCard}>
                          <Image
                            src={
                              getDirectusAssetURI(product?.icon ?? '') || ''
                            }
                            alt={getAltProperty(
                              filesMeta,
                              product?.icon ?? '',
                              'Banner Image',
                            )}
                            width={60}
                            height={60}
                          />
                          <div className={Styles.iconCardBody}>
                            <h4 className={Styles.title}>{product?.title}</h4>
                            <div className={Styles.description}>
                              {product?.subtitle}
                            </div>
                          </div>
                        </div>
                      </Link>
                  )})}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconCardSection;
