'use client';

import { Form, message } from 'antd';
import { usePathname } from 'next/navigation';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import FormInput from '@/app/_components/form/FormInput';
import { Form as FormType } from '@/app/_types/components/form';
import { saveFormData } from '@/app/actions';

import Styles from './ContactForm.module.scss';

type Props = FormType & {};

const ContactForm = (props: Props) => {
  const pathname = usePathname();
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();
  const saveData = saveFormData.bind(null, pathname, props.submission_key);

  const handleSubmit = async (data: any) => {
    const response = await saveData(data);

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
    } else {
      form.resetFields();
      messageApi.open({
        type: 'success',
        content: props.submitted_paragraph,
        duration: 10,
      });
    }
  };

  return (
    <div className={Styles.contactFormWrapper}>
      <div className="headingElement">
        <h3 className="subTitle">{props.pre_text}</h3>
        <h2 className="titleXl">{props.title}</h2>
      </div>
      <Form
        form={form}
        className={Styles.contactForm}
        size="large"
        onFinish={handleSubmit}
      >
        <div className={Styles.contactFormField}>
          {props.form_inputs.map((input) => (
            <FormInput key={input.id} input={input} form={form} />
          ))}
        </div>
        <div>
          <ButtonCustom htmlType="submit">{props.submit_text}</ButtonCustom>
        </div>
      </Form>
    </div>
  );
};

export default ContactForm;
