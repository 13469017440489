'use client';
import Image from 'next/image';
import React, { useRef } from 'react';
import { Autoplay, Parallax } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { CareersImages } from '@/app/_types/components/career-images';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './ImageMarquee.module.scss';

type ImageMarqueeProps = {
  slider: CareersImages[];
  filesMeta: FilesMeta;
};

const ImageMarquee = (props: ImageMarqueeProps) => {
  const { slider, filesMeta } = props;

  const swiperRef = useRef<any>(null);

  const handleMouseEnter = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.params.autoplay.delay = 5;
      swiperRef.current.swiper.autoplay.start();
    }
  };

  const handleMouseLeave = () => {
    swiperRef.current?.swiper?.autoplay?.stop();
  };

  const swiperConfig: Parameters<typeof Swiper>[0] = {
    spaceBetween: 0,
    loop: true,
    slidesPerView: 'auto',
    watchSlidesProgress: true,
    speed: 3000,
    autoplay: false,
  };

  return (
    <div className="pageSection">
      <div
        className={Styles.imageMarqueeWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Swiper
          ref={swiperRef}
          modules={[Autoplay, Parallax]}
          className="mySwiper"
          {...swiperConfig}
        >
          {slider.map((item, index) => (
            <SwiperSlide key={index} className={Styles.imageMarqueeContaier}>
              <div className={Styles.imageMarqueeLink}>
                <Image
                  src={getDirectusAssetURI(item?.image ?? '') || ''}
                  alt={getAltProperty(filesMeta, item?.image ?? '', 'Image')}
                  width={414}
                  height={480}
                  className={Styles.imageMarquee}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ImageMarquee;
