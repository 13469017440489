import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './ProductCard.module.scss';

type ProductCardProps = {
  icon?: string | null;
  title?: string | null;
  url?: string | null;
  paragraph?: string | null;
  filesMeta?: FilesMeta;
  description?: string | null;
};

const ProductCard = (props: ProductCardProps) => {
  const { icon, title, paragraph, url, filesMeta = {}, description } = props;

  return (
    <Link href={url ?? ''} className={Styles.productCard}>
      <Image
        src={getDirectusAssetURI(icon ?? '') || ''}
        alt={getAltProperty(filesMeta, icon ?? '', 'Icon')}
        width={50}
        height={50}
      />
      <div className={Styles.productCardBody}>
        <div className="headingElement">
          <h3 className="titleMd">{title}</h3>
        </div>
        {paragraph && <div className={Styles.description}>{paragraph}</div>}
        {description && <div className={Styles.description}>{description}</div>}
      </div>
    </Link>
  );
};

export default ProductCard;
